<template>
  <div>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        v-model="setting_dialog"
        temporary
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-tabs fixed-tabs color="grey darken-2">
                <v-tab>Основні</v-tab>
                <v-tab>Додаткові</v-tab>

                <v-tab-item class="mt-3 py-2">
                  <v-row>
                    <v-col cols="12">
                      <v-select filled v-model="filters.debt_column"
                                color="grey"
                                :items="debt_columns"
                                label="Колонка заборгованості"
                                hide-details/>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-switch
                          v-model="filters.debt_by_term"
                          inset
                          hide-details
                          color="success"
                          class="mt-0 ml-2"
                          :label="filters.debt_by_term ? 'Відбір по терміну (включений)' : 'Відбір по терміну (виключений)'"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field filled :disabled="!filters.debt_by_term" color="grey"
                                    label="Термін від" hide-details
                                    v-model="filters.debt_by_term_start"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field filled :disabled="!filters.debt_by_term" color="grey"
                                    label="Термін до" hide-details
                                    v-model="filters.debt_by_term_end"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col cols="12" md="12">
                      <v-switch
                          v-model="filters.debt_by_start_balance"
                          inset
                          hide-details
                          color="success"
                          class="mt-0 ml-2"
                          :label="filters.debt_by_start_balance ? 'Відбір по поч.сальдо (включений)' : 'Відбір по поч.сальдо (виключений)'"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field filled :disabled="!filters.debt_by_start_balance" color="grey"
                                    label="Сальдо поч. від" hide-details
                                    v-model="filters.debt_by_start_balance_start"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field filled :disabled="!filters.debt_by_start_balance"
                                    label="Сальдо поч. до" hide-details color="grey"
                                    v-model="filters.debt_by_start_balance_end"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col cols="12" md="12">
                      <v-switch
                          v-model="filters.debt_by_end_balance"
                          inset
                          hide-details
                          color="success"
                          class="mt-0 ml-2"
                          :label="filters.debt_by_end_balance ? 'Відбір по кін.сальдо (включений)' : 'Відбір по кін.сальдо (виключений)'"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field filled :disabled="!filters.debt_by_end_balance" color="grey"
                                    label="Сальдо кін. від" hide-details
                                    v-model="filters.debt_by_end_balance_start"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field filled :disabled="!filters.debt_by_end_balance" color="grey"
                                    label="Сальдо кін. до" hide-details
                                    v-model="filters.debt_by_end_balance_end"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col cols="12" md="12">
                      <v-switch
                          v-model="filters.debt_by_end_balance_minus_pay"
                          inset
                          hide-details
                          color="success"
                          class="mt-0 ml-2"
                          :label="filters.debt_by_end_balance_minus_pay ? 'Відбір по поч.сальдо з урах.оплати (включений)' : 'Відбір по поч.сальдо з урах.оплати (виключений)'"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field filled :disabled="!filters.debt_by_end_balance_minus_pay"
                                    label="Поч. сальдо з урах.оплати від" hide-details color="grey"
                                    v-model="filters.debt_by_end_balance_minus_pay_start"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field filled :disabled="!filters.debt_by_end_balance_minus_pay"
                                    label="Поч. сальдо з урах.оплати до" hide-details color="grey"
                                    v-model="filters.debt_by_end_balance_minus_pay_end"></v-text-field>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="mt-3 py-2">
                  <v-row>
                    <v-col cols="12">
                      <AddressElementSelect v-model="filters.city"
                                            label="Населений пункт" filled
                                            select_type="city"
                      />
                    </v-col>
                    <v-col cols="12">
                      <AddressElementSelect :parent_id="filters.city" v-model="filters.streets"
                                            label="Вулиця(-ки)" multiple filled
                                            select_type="street" use_parent_id
                                            :disabled="!filters.city"
                      />
                    </v-col>
                    <v-col cols="12">
                      <AddressElementSelect :parent_id="filters.streets" v-model="filters.buildings"
                                            label="Будинок(-ки)" multiple filled
                                            select_type="building" use_parent_id
                                            :disabled="!filters.streets.length"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-select filled v-model="filters.services"
                                color="grey"
                                :items="services"
                                label="Послуги"
                                multiple
                                hide-details/>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea auto-grow rows="2"
                                  v-model="filters.additional_text"
                                  hide-details color="grey" filled clearable
                                  label="Додатковий текст" />
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="pages" id="print">
      <div class="page page-a4 page-separator page-for-print" v-for="(page, index) in pages" :key="`page-${index}`"
           style="margin-bottom: 2px;">
        <div class="report-section" style="flex-wrap: wrap">
          <div class="rp-col-12">
            <div style="font-size: 1rem; text-align: center; font-weight: bold; margin-bottom: 12px">
              <template v-if="filters.debt_column === 'debt_by_start_balance'">
                Список боржників по {{ organization.short_name }} станом на {{ date_start | formatDate }} р.
              </template>
              <template v-else-if="filters.debt_column === 'debt_by_end_balance'">
                Список боржників по {{ organization.short_name }} станом на {{ date_end | formatDate }}  р.
              </template>
              <template v-else-if="filters.debt_column === 'debt_by_end_balance_minus_pay'">
                Список боржників по {{ organization.short_name }} станом на {{ date_end | formatDate }}  р.
              </template>
              <template v-else>
                Список боржників по {{ organization.short_name }} станом на {{ date_start | formatDate }} р.
              </template>
            </div>
            <div class="d-flex flex-nowrap">
              <div style="flex: 0 0 80px; font-size: .9rem; font-weight: bold">Будинок:</div>
              <div style="font-size: .9rem">
                {{ ((items.filter(item => item.page === page) || [])[0] || {}).building_address || '' }}
              </div>
            </div>
            <div class="d-flex flex-nowrap">
              <div style="flex: 0 0 80px; font-size: .9rem; font-weight: bold">Під'їзд:</div>
              <div style="font-size: .9rem">
                Під'їзд №{{ ((items.filter(item => item.page === page) || [])[0] || {}).entrance || '0' }}
              </div>
            </div>
            <div class="d-flex flex-nowrap py-2" style="max-height: 3.8rem; overflow: hidden; font-size: 12px">
              {{ filters.additional_text }}
            </div>
          </div>
          <div class="rp-col-12 mt-4">
            <div class="report-table">
              <div class="report-table-row">
                <div class="report-table-col" style="flex: 0 0 100px">
                  <span>№ з/п</span>
                </div>
                <div class="report-table-col" style="flex: 0 0 400px">
                  <span>Абонент</span>
                </div>
                <div class="report-table-col" style="flex: 1;">
                  <span>Заборгованість</span>
                </div>
              </div>
              <div class="report-table-row" v-for="(col, idx) in items.filter(item => item.page === page)"
                   :key="`col-idx-${col.flat_id}`">
                  <div class="report-table-col" style="flex: 0 0 100px; font-size: 1.2rem">
                    <span class="text-no-wrap">
                      {{ idx + 1 }}
                    </span>
                  </div>
                  <div class="report-table-col" style="flex: 0 0 400px">
                    <span
                        class="text-no-wrap"
                        style="width: 399px; justify-content: left; padding-left: 10px; font-size: 1.2rem; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                      {{ col.flat_number }}
                    </span>
                  </div>
                  <div class="report-table-col" style="flex: 1">
                    <span style="justify-content: right; padding-right: 10px; font-size: 1.2rem">
                      {{ col.debt_sum | formatNumber }}
                    </span>
                  </div>
              </div>
              <div class="report-table-row">
                <div class="report-table-col" style="flex: 0 0 500px">
                  <span style="justify-content: right; padding-right: 10px; font-weight: bold; font-size: 1.1rem">
                    Всього по під'їзду:
                  </span>
                </div>
                <div class="report-table-col" style="flex: 1;">
                  <span style="justify-content: right; padding-right: 10px; font-weight: bold; font-size: 1.1rem">
                    {{ getTotal(page, 'debt_sum') | formatNumber }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="separator"></div>
      </div>
    </div>
  </div>
</template>

<script>

import {formatDate} from "@/filters";
import reportAPI from "@/utils/axios/reports";
import {ALERT_SHOW} from "@/store/actions/alert";
import {mapGetters} from "vuex";

export default {
  name: "debt_by_pages",
  props: ['organization', 'print_data', 'global_settings', 'scale', 'date_start', 'date_end', 'generate', 'clear',
          'generate_xlsx', 'generate_email', 'email_address'],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
  },
  computed: {
    ...mapGetters(
        {
          services: 'getServicesSelectWithoutAll'
        }
    ),
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    },
  },
  data() {
    return {
      pages: 0,
      per_page: 0,
      items: [],
      setting_dialog: this.global_settings,
      current_date: this.getCurrentDate(),
      settings_watcher: null,
      generate_watcher: null,
      generate_xlsx_watcher: null,
      generate_email_watcher: null,
      clear_watcher: null,
      debt_columns: [
        { text: 'Борг на початок місяця', value: 'debt_by_start_balance' },
        { text: 'Борг на кінець місяця', value: 'debt_by_end_balance' },
        { text: 'Борг на поч. міс. за "-" оплати', value: 'debt_by_end_balance_minus_pay' },
      ],
      filters: {
        debt_by_term: true,
        debt_by_term_start: 3,
        debt_by_term_end: 999,
        debt_by_start_balance: false,
        debt_by_start_balance_start: 0,
        debt_by_start_balance_end: 0,
        debt_by_end_balance: false,
        debt_by_end_balance_start: 0,
        debt_by_end_balance_end: 0,
        debt_by_end_balance_minus_pay: false,
        debt_by_end_balance_minus_pay_start: 0,
        debt_by_end_balance_minus_pay_end: 0,
        city: null,
        streets: [],
        buildings: [],
        floor: 0,
        services: [],
        additional_text: '',
        debt_column: ''
      },
    }
  },
  methods: {
    onNavInput(e) {
      if (!e) {
        this.$emit('closeSettings')
      }
    },
    generate_xlsx_file() {
      if (!this.debt_columns) {
        this.$store.commit(ALERT_SHOW, { text: 'Вкажіть колонку заборгованості для відображення', color: 'error' })
        return
      }
      if (this.date_start && this.date_end) {
        this.$emit('xlsx_loading_status', true)
        const payload = {date_start: this.date_start, date_end: this.date_end, filters: this.filters}

        reportAPI.debt_report_by_pages_xlsx(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = `debt_report_by_pages_${formatDate(this.date_start, 'MM_YYYY')}_${formatDate(this.date_end, 'MM_YYYY')}.xlsx`
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
              this.$emit('xlsx_loading_status', false)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
              this.$emit('xlsx_loading_status', false)
            })
            .finally(() => {
              this.$emit('generate_xlsx')
            })
      }
    },
    generate_report() {
      if (!this.debt_columns) {
        this.$store.commit(ALERT_SHOW, { text: 'Вкажіть колонку заборгованості для відображення', color: 'error' })
        return
      }
      if (this.date_start && this.date_end) {
        this.$emit('report_loading_status', true)
        const payload = {date_start: this.date_start, date_end: this.date_end, filters: this.filters}

        reportAPI.debt_report_by_pages(payload)
            .then(response => response.data)
            .then(data => {
              this.items = Object.freeze(data.items)
              this.pages = data.pages
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.expanded = []
              this.$emit('generate_report', !!this.items)
              this.$emit('report_loading_status', false)
            })
      }
    },
    generate_email_send() {
      if (!this.debt_columns) {
        this.$store.commit(ALERT_SHOW, { text: 'Вкажіть колонку заборгованості для відображення', color: 'error' })
        return
      }
      if (this.date_start && this.date_end) {
        this.$emit('email_loading_status', true)
        const payload = {date_start: this.date_start, date_end: this.date_end, filters: this.filters, email: this.email_address}
        reportAPI.debt_report_by_pages_email(payload)
            .then(response => response.data)
            .then(data => {
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {color: data.status === 'OK' ? 'success' : 'error', text: data.text})
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_email')
            })
      }
    },
    formatDate,
    getTotal(page, col, value = '') {
      return this.items.filter(item => item.page === page).reduce((sum, item) => sum + (value ? item[col][value] : item[col]), 0)
    },
    getCurrentDate() {
      const date = new Date()
      const year = date.getUTCFullYear()
      const month = date.getUTCMonth() + 1 < 10 ? '0' + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1
      const day = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()
      return `${year}-${month}-${day}`
    },
    closeSettings() {
      this.setting_dialog = false
      this.$emit('closeSettings')
    },
    watch_settings() {
      this.settings_watcher = this.$watch(
          'global_settings',
          {
            handler(payload) {
              this.setting_dialog = payload
            }
          }
      )
    },
    watch_generate_xlsx() {
      this.generate_xlsx_watcher = this.$watch(
          'generate_xlsx',
          {
            handler(payload) {
              if (payload) {
                this.generate_xlsx_file()
              }
            }
          }
      )
    },
    watch_generate_email() {
      this.generate_email_watcher = this.$watch(
          'generate_email',
          {
            handler(payload) {
              if (payload) {
                this.generate_email_send()
              }
            }
          }
      )
    },
    watch_generate() {
      this.generate_watcher = this.$watch(
          'generate',
          {
            handler(payload) {
              if (payload) {
                this.generate_report()
              }
            }
          }
      )
    },
    watch_clear() {
      this.clear_watcher = this.$watch(
          'clear',
          {
            handler(payload) {
              if (payload) {
                this.items = []
                this.pages = 0
                this.per_page = 0
                this.$emit('afterClear')
              }
            }
          }
      )
    },
  },
  created() {
    this.watch_settings()
    this.watch_generate()
    this.watch_clear()
    this.watch_generate_xlsx()
    this.watch_generate_email()

    reportAPI.report_settings("debt_report_by_pages")
        .then(response => response.data)
        .then(data => {
          const server_filters = JSON.parse(data)
          Object.keys(this.filters).forEach(key => {
            if (server_filters[key] !== undefined) {
              this.filters[key] = server_filters[key]
            }
          })
          if (!this.filters.debt_column && !this.filters.debt_by_term_start && !this.filters.debt_by_start_balance && !this.filters.debt_by_end_balance) {
            this.filters.debt_column = 'debt_by_start_balance'
            this.filters.debt_by_term = true
            this.filters.debt_by_term_start = 3
            this.filters.debt_by_term_end = 9999
          }
        })
  },
  beforeDestroy() {
    if (this.settings_watcher) {
      this.settings_watcher()
    }
    if (this.generate_watcher) {
      this.generate_watcher()
    }
    if (this.clear_watcher) {
      this.clear_watcher()
    }
    if (this.generate_xlsx_watcher) {
      this.generate_xlsx_watcher()
    }
    if (this.generate_email_watcher) {
      this.generate_email_watcher()
    }
  }
}
</script>

<style scoped lang="scss">
$border-color: #cdcdcd;
$separator-border-color: #6b6b6b;

@media print {
  .page {
    padding-top: 5px !important;
    margin-top: 0 !important;
  }

  .page-separator {
    page-break-after: always;
    page-break-inside: avoid;
    page-break-before: avoid;
  }
}

.page-separator {
  page-break-after: always;
  page-break-inside: avoid;
  page-break-before: avoid;
}

.report-table {
  width: 100%;
  text-align: center;

  .report-table-row:nth-child(1) {
    & > div {
      background-color: rgb(249 249 249 / 74%) !important;
      border-top: 1px solid $border-color;
      border-right: 1px solid $border-color;
      font-weight: 500;

      &:nth-child(1) {
        border-left: 1px solid $border-color;
      }
    }
  }

  .report-table-row:last-child {
    & > div {
      border-bottom: 1px solid $border-color;
      background-color: rgb(249 249 249 / 74%) !important;
    }
  }

  .report-table-row {
    min-height: 24px;
    line-height: 1.8rem;
    font-size: 16px;

    & > div {
      border-top: 1px solid $border-color;
      border-right: 1px solid $border-color;

      &:nth-child(1) {
        border-left: 1px solid $border-color;
      }
    }
  }

}

.report-table-col {
  //&:nth-child(1) {
  //  span {
  //    line-height: 0;
  //    justify-content: flex-start;
  //    white-space: nowrap;
  //    text-align: left;
  //    width: 140px;
  //    overflow: hidden;
  //  }
  //}

  span {
    padding: 0 3px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

</style>